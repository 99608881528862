<template lang="html">
    <div class="ChatVideo">
        <h1>{{ title }}</h1>
        <i v-if="secretIcon" class="fas fa-user-secret"></i>
        <div class="ChatVideo--Messages" ref="messages">
            <div v-for="(m, index) in localMessages" :key="m.id">
                <div class="chatMessage"
                    :class="{ 'chatMessage--mod': m.isModerator && isUser, 'chatMessage--user': (isUser && userProfile.name != m.nickname && !m.isModerator) || (!m.isModerator && isClient) }">
                    <div class="chatMessage__Message">
                        <div>
                            <p v-if="m.isModerator">{{ $t('role_moderator') }}, {{
                                $filters.formatDateFull(m.creationDate)
                                }}
                            </p>
                            <p v-if="!m.isModerator && !m.nickname">{{ getNicknameById(m.ownerId) }}, {{ $filters.formatDateFull(m.creationDate) }}</p>
                            <p v-if="!m.isModerator && m.nickname">{{ m.nickname }}, {{ $filters.formatDateFull(m.creationDate) }}</p>
                            <p v-if="!m.image && !m.video && !m.file" class="chatMessage__Text">{{ m.message }}</p>
                            <div class="chatMessage__Media" v-if="m.file">
                                <a :href="m.file" v-if="m.file"><i class="fas fa-file-download"></i>{{
                                    $t('comment_download_subject') }}</a>
                            </div>
                            <MediaViewer v-if="m.image" :images="$filters.formatMedia([m.image])" />
                            <MediaViewer v-if="m.video" :videos="$filters.formatVideoMedia([m.video])" />
                        </div>
                        <div @click="toggleTooltip(m)" class="tooltipContainer"
                            v-if="isModerator || isCommunityMainAdmin">
                            <i class=" fas fa-ellipsis-v"></i>
                            <div class="tooltipMenu" :class="{ 'tooltipMenu--first': index === 0 }" v-if="m.showTooltip"
                                @click="handleClick(m)">
                                <p>Eliminar</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ChatVideo--Media" v-if="fileUploadPath && !disableActions">
            <div v-if="!showChatVideoUploader && !showChatFileUploader">
                <span class="ChatVideo--MediaIcon" @click="showChatImageUploader = !showChatImageUploader">
                    <i class="fa fa-image"></i>
                </span>
                <transition name="fade">
                    <Dropzone v-if="showChatImageUploader" :uploadUrl="fileUploadPath" :uploadHeaders="uploadHeaders"
                        :uploadText="$t('comment_upload_image')" acceptedFiles="image/jpg,image/jpeg,image/png"
                        :maxFiles="1" @file-uploaded="handleAddFile" @file-removed="handleRemoveFile">
                    </Dropzone>
                </transition>
            </div>
            <div v-if="!showChatVideoUploader && !showChatImageUploader">
                <span class="ChatVideo--MediaIcon" @click="showChatFileUploader = !showChatFileUploader">
                    <i class="fa fa-file"></i>
                </span>
                <transition name="fade">
                    <Dropzone v-if="showChatFileUploader" :uploadUrl="fileUploadPath" :uploadHeaders="uploadHeaders"
                        :uploadText="$t('comment_upload_file')" :maxFiles="1" @file-uploaded="handleAddFile"
                        @file-removed="handleRemoveFile">
                    </Dropzone>
                </transition>
            </div>
            <div v-if="!showChatFileUploader && !showChatImageUploader">
                <span class="ChatVideo--MediaIcon" @click="showChatVideoUploader = !showChatVideoUploader">
                    <i class="fa fa-video"></i>
                </span>
                <transition name="fade">
                    <Dropzone v-if="showChatVideoUploader" :uploadUrl="fileUploadPath" :uploadHeaders="uploadHeaders"
                        :uploadText="$t('comment_upload_video')" :maxFiles="1" acceptedFiles="video/*"
                        @file-uploaded="handleAddFile" @file-removed="handleRemoveFile">
                    </Dropzone>
                </transition>
            </div>
        </div>
        <form class="ChatVideo--input" v-show="!disableActions">
            <textarea type="text" :disabled="disabled || messageFile != null"
                v-on:keyup.enter.exact="videochatSendMessage($event)" v-model="message"
                :placeholder="messageFile ? $t('chat_image_message_info') : $t('videochat_chat_input')"></textarea>
            <button type="submit" :disabled="disabled" v-on:click="videochatSendMessage($event)">
                <i class="fas fa-paper-plane"></i>
            </button>
        </form>

    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ApiService from '#/services/api.service';
import store from '@/store';
import {
    COMMUNITY_DELETE_CHAT_MESSAGE
} from '@/store/community/chat.module';
import moment from 'moment-timezone';
import Dropzone from '~/components/Dropzone'
import MediaViewer from '~/components/MediaViewer';
import { forEach } from 'lodash';
import Swal from 'sweetalert2'

export default {
    name: 'VideoChatPage',
    components: {
        Dropzone,
        MediaViewer
    },
    computed: {
        ...mapGetters([
            'isClient',
            'isUser',
            'isObservator',
            'isCommunityMainAdmin',
            'isModerator',
            'userProfile'
        ]),
    },
    props: {
        messages: {
            type: Array,
        },
        title: {
            type: String,
        },
        user: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disableActions: {
            type: Boolean,
            default: false
        },
        secretIcon: {
            type: Boolean,
            default: false
        },
        members: Object,
        moderatorUser: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileUploadPath: process.env.VUE_APP_API_URL + '/experienceLabs/file',
            videoUploadPath: process.env.VUE_APP_API_URL + '/experienceLabs/video',
            uploadHeaders: ApiService.getConfig().headers,
            showChatFileUploader: false,
            showChatImageUploader: false,
            showChatVideoUploader: false,
            message: "",
            messageFile: null,
            selectedMessages: [],
            isTooltipClicked: false,
            localMessages: this.messages,
            isTooltipClicked: false
        }
    },
    watch: {
        messages: {
            handler(newMessages) {
                if(newMessages != undefined) this.localMessages = JSON.parse(JSON.stringify(newMessages));
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            enlargePic: 'enlargePic',
        }),
        async videochatSendMessage(event) {
            if (event != null) event.preventDefault()
            let type = ''
            if (this.showChatImageUploader) type = 'image'
            else if (this.showChatFileUploader) type = 'file'
            else if (this.showChatVideoUploader) type = 'video'
            let data = { message: this.message }
            if (this.messageFile != null) {
                data[type] = this.messageFile
                data.message = type
            }
            this.$emit('send-message', data)
            this.message = ''
            this.messageFile = null
            this.showChatImageUploader = false
            this.showChatFileUploader = false
            this.showChatVideoUploader = false
        },
        handleAddFile(file, response) {
            if (!this.messageFile) this.messageFile = response.path;
            this.videochatSendMessage(null)
        },
        handleRemoveFile: function (file) {
            if (this.messageFile == null) return
            if (this.messageFile.match(file.name)) this.messageFile = null;
        },
        automaticScroll() {
            if (!this.isTooltipClicked) { // Condición para verificar si se ha hecho clic en el tooltip
                this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
            }
        },
        adjustMessagesScroll() {
            if (!this.isTooltipClicked) { // Condición para verificar si se ha hecho clic en el tooltip
                setTimeout(() => {
                    this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
                }, 500);
            }
        },
        getNicknameById(memberId) {
            if (!this.isClient && Object.keys(this.members).length < 1) {
                return this.$t('chat_user_participant')
            } else {
                if (memberId !== null && memberId !== undefined) {
                    for (let key in this.members) {
                        if (this.members.hasOwnProperty(key)) {
                            let member = this.members[key];
                            if (member.identifier === memberId) {
                                if (member.nickname == null || (member.nickname != null && member.nickname.includes("-" + member.identifier))) {
                                    return member.name;
                                } else {
                                    return member.nickname;
                                }
                            }
                        }
                    }
                }
                return null;
            }
        },
        toggleTooltip(message) {
            this.isTooltipClicked = true;
            this.localMessages.forEach(m => {
                if (m.identifier !== message.identifier) {
                    m.showTooltip = false;
                }
            });
            message.showTooltip = !message.showTooltip;
            setTimeout(() => {
                message.showTooltip = false;
                this.isTooltipClicked = false;

            }, 1500);
        },
        handleClick(message) {
            const condition = this.isModerator || this.isCommunityMainAdmin
            if (condition) {
                this.deleteSelectedMessages(message);
            }
        },
        async deleteSelectedMessages(message) {
            const { isConfirmed } = await Swal.fire({
                title: '¿Estás seguro?',
                text: '¿Estás seguro de que deseas eliminar este mensaje?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });
            if (isConfirmed) {
                this.selectedMessages.push(message.identifier);
                try {
                    await store.dispatch(COMMUNITY_DELETE_CHAT_MESSAGE, { messageIds: this.selectedMessages });
                    this.localMessages = this.localMessages.filter(m => m.identifier !== message.identifier);
                    this.clearSelectedMessages();
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.clearSelectedMessages();
                return;
            }
        },

        clearSelectedMessages() {
            this.selectedMessages = [];
        },
    },
    updated() {
        this.automaticScroll();
    }
}
</script>

<style scoped lang="scss">
.ChatVideo {
    background: #F5F5F5;
    border: 1px solid #D3D3D3;
    padding: 15px;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 22px;
        text-align: center;
        color: #3E3E3E;
        font-weight: normal;
        margin-bottom: 10px;
    }

    &--Messages {
        flex-grow: 1;
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;

        &::-webkit-scrollbar-track {
            background-color: #e6e6e6;
        }

        &::-webkit-scrollbar {
            background-color: #8134ff;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: var(--primary-color);
        }
    }

    &--Media {
        flex-grow: 0;
        display: flex;
    }

    &--MediaIcon {
        background-color: #F2F2F2;
        margin: 5px 0;
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--input {
        flex-grow: 0;
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        margin: 10px 0;
        font-family: Roboto Condensed;

        button {
            border: none;
            background: transparent;
            outline: none;
        }

        textarea {
            height: 100%;
            border: none;
            width: 100%;
            outline: none;
            resize: none;
            padding-top: 10px;
        }

        i {
            color:var(--accent-color);
            font-size: 16px;
        }
    }
}

.chatMessage {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-height: 64px;

    &--user {
        justify-content: flex-start;

        .chatMessage {
            &__Message {
                background: #F7F4F8;

                p:first-child {
                    text-align: left;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 17px;
                    left: -16px;
                    border-width: 8px;
                    border-style: solid;
                    border-color: transparent #F7F4F8 transparent transparent;
                }
            }
        }
    }

    &__Message {
        display: flex;
        margin: 10px;
        margin-left: 15px;
        font-size: 17px;
        color: #3E3E3E;
        max-width: 65%;
        padding: 10px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;

        p:first-child {
            font-size: 13px;
            color: #3E3E3E;
            opacity: 0.5;
            text-align: right;
        }
    }

    &__Text {
        word-break: break-word;
    }

    &__Media {
        width: 100%;

        a {
            color: #3e3e3e;
        }

        i {
            margin-right: 5px;
        }

        img,
        video {
            width: 100%;
            border: 1px solid #D3D3D3;
            cursor: pointer;
        }
    }
}

.fa-user-secret {
    position: absolute;
    top: -8px;
    font-size: 100px;
    transform: rotate(-45deg);
    opacity: 0.1;
    left: 0px;
}

.tooltipContainer {
    padding-left: 10px;
    position: relative;
    opacity: 0.2;
    transition: all .3s ease;
    z-index: 999;
}

.chatMessage__Message:hover .tooltipContainer {
    opacity: 1;
}

.tooltipMenu {
    position: absolute;
    bottom: calc(100% + 6px);
    right: -3px;
    background: white;
    border: 1px solid #D3D3D3;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all 0.3s ease;
    z-index: 998;


    &::before {
        content: "";
        position: absolute;
        top: 100%;
        right: 0;
        border-width: 5px;
        border-style: solid;
        border-color: #D3D3D3 transparent transparent transparent;
    }

    p {
        color: black !important;
    }

    &:hover {
        cursor: pointer;
        background: #F5F5F5;
        border: 1px solid #D3D3D3;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);

        p {
            color: #3E3E3E;
        }

    }
}

.tooltipMenu--first {
    bottom: auto;
    top: calc(100% - 6px);
    transform: translateY(0);

}

.tooltipMenu--first::before {
    top: auto;
    bottom: 100%;
    border-color: transparent transparent #D3D3D3 transparent;
}
</style>
