<template lang="html">
  <div class="AccessPermissions">
    <h1>Usuarios</h1>
    <h2>Permisos de usuario</h2>
    <div class="AccessPermissions--List">
      <div class="AccessPermissions--User" v-for="tr in accessUsers" v-show="(tr.online && communityCurrentActivity.publicActivity) || !communityCurrentActivity.publicActivity">
        <img v-show="tr.accessGranted" src="@/assets/img/active-bg.svg">
        <SwitchInput
          v-model="tr.accessGranted"
          :text="(tr.accessGranted ? ' <b>'+ tr.testerName +'</b>' : $t('enable')  + ' ' + $t('access_to') +' <b>'+ tr.testerName +'</b>')"
          :disabled="tr.accessGranted"
          v-on:input="$emit('grant-access', tr)"/>
      </div>
      <p class="AccessPermissions--NoUsers" v-show="usersPermissionCount() == 0">Actualmente no hay usuarios conectados.</p>
    </div>
    <h2>Moderadores online</h2>
    <div class="AccessPermissions--List">
      <div class="AccessPermissions--User" v-for="user in onlineUsers.moderatorsOnline">
        <span></span>{{user}}
      </div>
      <p class="AccessPermissions--NoUsers" v-show="onlineUsers.moderatorsOnline.length == 0">Actualmente no hay moderadores conectados.</p>
    </div>
    <h2>Observadores online</h2>
    <div class="AccessPermissions--List">
      <div class="AccessPermissions--User" v-for="user in onlineUsers.observersOnline">
        <span></span>{{user}}
      </div>
      <p class="AccessPermissions--NoUsers" v-show="onlineUsers.observersOnline.length == 0">Actualmente no hay observadores conectados.</p>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations  } from 'vuex';
import SwitchInput from '~/components/SwitchInput/SwitchInput'
import {
  COMMUNITY_FETCH_VIDEO_CHAT
 } from '@/store/community/chat.module';
export default {
  name: 'AccessPermissions',
  components:{
    SwitchInput
  },
  computed:{
    ...mapGetters([
      'communityCurrentActivity',
      'onlineUsers'
    ])
  },
  props:{
    accessUsers: Object
  },
  methods:{
    usersPermissionCount(){
      let users = 0
      Object.entries(this.accessUsers).map(item => {
        if((item[1].online && this.communityCurrentActivity.publicActivity) || !this.communityCurrentActivity.publicActivity) users++
      })
      return users
    }
  }
}
</script>
<style lang="scss" scoped>
.AccessPermissions{
  background: #F5F5F5;
  border: 1px solid #D3D3D3;
  padding: 15px;
  height: 100%;
  h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 0.01em;
    color: #404040;
  }
  h2{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #939393;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  &--NoUsers{
    font-family: Roboto;
  }
  &--List{
    display: flex;
    flex-direction: column;
    border: 1px solid #E1E1E1;
    padding: 5px;
    // height: calc(100vh - 210px);
    // overflow: auto;
    // &::-webkit-scrollbar-track{
    //   background-color: #e6e6e6;
    // }
    // &::-webkit-scrollbar {
    //   background-color: #8134ff;
    //   width: 5px;
    // }
    // &::-webkit-scrollbar-thumb{
    //   border-radius: 10px;
    //   background: var(--primary-color);
    // }
  }
  &--User{
    padding: 10px;
    border-bottom: 1px solid lightgray;
    display: flex;
    align-items: center;
    gap: 5px;
    span{
      width: 13px;
      height: 13px;
      background:#A2DE62;
      border-radius: 50%;
    }
  }
}
</style>
