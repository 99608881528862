import axios from 'axios';
import queryString from 'query-string';
import * as _ from 'lodash';
import cookies from 'browser-cookies';

let config = {
  'withCredentials': true,
  'Access-Control-Allow-Credentials': true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
};

const updateConfig = (customConfig) => {
  config =  _.pickBy(_.assign({}, config, customConfig), _.identity);

  if ( config.interceptors ) {
    config.interceptors.map( int => {
      axios.interceptors[ int.type ].use( int.success_cb, int.error_cb )
    } );
  }
};

const setAuthToken = () => {
  updateConfig({
    headers: {
      'authToken': config.token(),
    }
  })
};

export default {
  setConfig(customConfig) {
    updateConfig(customConfig);
  },

  getConfig() {
    return config;
  },

  get(path, params, customConfig) {
    setAuthToken();
    updateConfig(customConfig);

    let query = '';

    if(params) {
      query = '?' + queryString.stringify(params);
    }
    if(customConfig == undefined){
      config.responseType = ""
    }
    return axios.get(path + query, config);
  },

  post(path, body, customConfig) {
    setAuthToken();
    updateConfig(customConfig);
    if(customConfig == undefined){
      config.responseType = ""
    }
    return axios.post(path, body, config);
  },

  postFile(path, body, customConfig) {
    setAuthToken();
    updateConfig(customConfig)
    config.responseType = ""
    config.data = undefined
    return axios.post(path, body, config);
  },

  put(path, body, customConfig) {
    setAuthToken();
    updateConfig(customConfig);
    if(customConfig == undefined){
      config.responseType = ""
    }
    return axios.put(path, body, config);
  },

  delete(path, body, customConfig) {
    setAuthToken();
    updateConfig(customConfig);
    if(customConfig == undefined){
      config.responseType = ""
    }
    config.data = body
    return axios.delete(path,config, {
      data:body
    });
  },
}
